<template>
  <div>
    <vx-card>
      <AgeGroupSidebar
        :isSidebarActive="isSidebarActive"
        @refreshData="toggleRefreshData"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
        :dataId = "dataId"
      />
      <div class="flex flex-wrap items-center space-between">
        <div class="mb-8" >
          <h2 class="mb-0">Age Group List</h2>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-full" align="right">
            <vs-button class="mb-md-0 mb-2"
                       align="right"
                       @click="addAgeGroup"
            >Add Age Group
            </vs-button>
          </div>
        </div>
      </div>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :data="ageGroups"
          max-items="10"
          :pagination="ageGroups.length > 0"
        >
          <template slot="thead">
            <vs-th>Age Group</vs-th>
            <vs-th class="action-col justify-center">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.ageGroup">
                {{ tr.ageGroup  }}
              </vs-td>
              <vs-td :data="tr._id" class="text-center">
                <a href="#" @click.stop="editDetailHandler(tr, tr._id)">
                  <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"  />
                </a>
                <a href="#" @click.stop="deleteHandler(tr._id)">
                  <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"  />
                </a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
  import vSelect from "vue-select";
  import { mapActions } from "vuex";
  import AgeGroupSidebar from "./AgeGroupSidebar";
  import Swal from 'sweetalert2/dist/sweetalert2';
  import 'sweetalert2/src/sweetalert2.scss'

  export default {
    components: {
      "v-select": vSelect,
      AgeGroupSidebar
    },
    data() {
      return {
        isMounted: false,
        serverResponded: false,
        ageGroups: [],

        //add story sidebar
        isSidebarActive: false,
        sidebarData: {},
        dataId:"",

      };
    },
    methods: {
      ...mapActions("ageGroup", [
        "fetchAgeGroups",
        "removeAgeGroup"
      ]),
      getAgeGroupList() {
        this.fetchAgeGroups().then(res => {
          this.ageGroups = res.data.data;
        });
      },

      addAgeGroup() {
        this.sidebarData = {}
        this.toggleDataSidebar(true)
      },
      editDetailHandler(data, id) {
        this.sidebarData = data;
        this.dataId = id
        this.toggleDataSidebar(true)
      },
      toggleDataSidebar(val = false) {
        this.isSidebarActive = val
      },
      toggleRefreshData(val = false) {
        if (val) {
          this.getAgeGroupList();
        }
      },
      deleteHandler(id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.acceptDeleteAlert(id)
          }
        })

      },
      acceptDeleteAlert(id) {
        this.$vs.loading()
        this.removeAgeGroup(id)
          .then(()   => {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Success',
              text: "Age Group Deleted Successfully.",
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success'
            })
            this.getAgeGroupList();
          })
          .catch(err => {
            this.$vs.loading.close()
          })
      },
    },

    created() {
      this.getAgeGroupList();
    }
  };
</script>
